.footer {
  padding-top: 47px;
  background-color: #10124d;
  color: #fff;
  @media #{$sp} {
    padding-top: 0;
  }
  .footer__link {
    display: flex;
    justify-content: center;
    @media #{$sp} {
      flex-direction: column;
      align-items: center;
    }
    li {
      margin-left: 47px;
      @media #{$sp} {
        margin-left: 0;
        margin-top: 25px;
      }
      .footer__link-items {
        color: #fff;
        font-size: 1.4rem;
        transition: 1s;
        &.-fb {
          display: block;
          width: 101px;
          height: 27px;
          img {
            width: 100%;
          }
        }
        &.-insta {
          display: block;
          width: 105px;
          height: 19px;
          img {
            width: 100%;
          }
        }
        &.-youtube {
          display: block;
          width: 97px;
          height: 17px;
          img {
            width: 100%;
          }
        }
        &.-tw {
          display: block;
          width: 79px;
          height: 18px;
          img {
            width: 100%;
          }
        }
      }
      .footer__link-items:hover {
        opacity: 0.7;
      }
    }
    li:first-child {
      margin-left: 0;
    }
  }
  .footer__copy-wrap {
    margin-top: 43px;
    padding: 19px 0 25px;
    border-top: 1px solid #fff;
    .footer__copy {
      text-align: center;
      small {
        font-size: 1.2rem;
        font-family: $aot101;
        font-weight: 400;
        font-style: normal;
      }
    }
  }
}