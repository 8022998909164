#goTop {
	position: fixed;
	right: 2%;
	bottom: 20px;
	z-index: 10;
	a{
		display: flex;
		justify-content: center;
		align-items: center;
		width: 38px;
		height: 38px;
		color: #fff;
		font-size: 1rem;
		font-weight: bold;
		font-family: Helvetica, sans-serif;
		line-height: 1;
		transition: all 0.3s;
		border-radius: 50%;
		border: 1px solid #a0a0a0;
		box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
		 span {
			position: relative;
			padding-top: 9px;
		}
		span:before {
			content: "";
			position: absolute;
			left: -4.55px;
			top: 0;
			width: 10px;
			height: 10px;
			border-top: 2px solid #a0a0a0;
			border-right: 2px solid #a0a0a0;
			-webkit-transform: rotate(-45deg);
			transform: rotate(-45deg);
			color: #1A2954;
		}
	}
}