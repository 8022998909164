//list-style

ul.dia {
	margin-top: 45px;
	padding-left: 16px;
	li {
		margin-top: 5px;
		position: relative;
		font-size: 1.4rem;
		line-height: 21px;
		font-family: $aot101;
		font-weight: 400;
		font-style: normal;
		color: #fff;
		letter-spacing: 0;
	}
	li:before {
		position: absolute;
		top: 0;
		left: -16px;
		content: "◆";
		font-size: 1.4rem;
		color: #fff;
	}
}
ul.disc {
	padding-left: 16px;
	li {
		position: relative;
		margin-top: 2px;
		list-style: disc;
		font-size: 1.4rem;
		line-height: 21px;
		font-family: $aot101;
		font-weight: 400;
		font-style: normal;
		color: #fff;
		letter-spacing: 0;
	}
}
