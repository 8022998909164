.animation {
	&.fadeInDown {
		-webkit-animation-fill-mode:both;
		-ms-animation-fill-mode:both;
		animation-fill-mode:both;
		-webkit-animation-duration:1s;
		-ms-animation-duration:1s;
		animation-duration:1s;
		-webkit-animation-name: fadeInDown;
		animation-name: fadeInDown;
		visibility: visible !important;
	}
	@-webkit-keyframes fadeInDown {
		0% { opacity: 0; -webkit-transform: translateY(-20px); }
		100% { opacity: 1; -webkit-transform: translateY(0); }
	}
	@keyframes fadeInDown {
		0% { opacity: 0; -webkit-transform: translateY(-20px); -ms-transform: translateY(-20px); transform: translateY(-20px); }
		100% { opacity: 1; -webkit-transform: translateY(0); -ms-transform: translateY(0); transform: translateY(0); }
	}
}

.animation-slow {
	&.fadeInDown {
		-webkit-animation-fill-mode:both;
		-ms-animation-fill-mode:both;
		animation-fill-mode:both;
		-webkit-animation-duration:2s;
		-ms-animation-duration:2s;
		animation-duration:2s;
		-webkit-animation-name: fadeInDown;
		animation-name: fadeInDown;
		visibility: visible !important;
	}
	@-webkit-keyframes fadeInDown {
		0% { opacity: 0; -webkit-transform: translateY(-50px); }
		100% { opacity: 1; -webkit-transform: translateY(0); }
	}
	@keyframes fadeInDown {
		0% { opacity: 0; -webkit-transform: translateY(-50px); -ms-transform: translateY(-50px); transform: translateY(-50px); }
		100% { opacity: 1; -webkit-transform: translateY(0); -ms-transform: translateY(0); transform: translateY(0); }
	}
}

.animation-deep-slow {
	&.fadeInDown {
		-webkit-animation-fill-mode:both;
		-ms-animation-fill-mode:both;
		animation-fill-mode:both;
		-webkit-animation-duration:3s;
		-ms-animation-duration:3s;
		animation-duration:3s;
		-webkit-animation-name: fadeInDown;
		animation-name: fadeInDown;
		visibility: visible !important;
		@media #{$sp} {
			-webkit-animation-fill-mode:both;
			-ms-animation-fill-mode:both;
			animation-fill-mode:both;
			-webkit-animation-duration:1s;
			-ms-animation-duration:1s;
			animation-duration:1s;
			-webkit-animation-name: fadeInDown;
			animation-name: fadeInDown;
			visibility: visible !important;
		}
	}
	@-webkit-keyframes fadeInDown {
		0% { opacity: 0; -webkit-transform: translateY(-50px); }
		100% { opacity: 1; -webkit-transform: translateY(0); }
	}
	@keyframes fadeInDown {
		0% { opacity: 0; -webkit-transform: translateY(-50px); -ms-transform: translateY(-50px); transform: translateY(-50px); }
		100% { opacity: 1; -webkit-transform: translateY(0); -ms-transform: translateY(0); transform: translateY(0); }
	}
}
