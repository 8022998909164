.page-title-wrap {
  width: 100%;
  height: 191px;
  background-color: #c7b299;
  display: flex;
  align-items: center;
  justify-content: center;
  .page-title {
    .image-about {
      width: 195px;
      height: 63px;
    }
  }
}

.about-idea {
  width: 100%;
  height: auto;
  padding-top: 124px;
  padding-bottom: 153px;
  background-image: url(../../_assets/img/page/page_bg_01.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;
  @media #{$sp} {
    background-image: url(../../_assets/img/page/page_bg_01_sp.jpg);
  }
  .about-idea__title {
    img {
      width: 225px;
      height: 61px;
    }
  }
  .about-idea__image {
    margin-top: 25px;
  }
  .about-idea__text {
    margin-top: 15px;
    img {
      width: 348px;
      height: 134px;
    }
  }
}

.about-profile {
  width: 100%;
  height: auto;
  padding-top: 116px;
  padding-bottom: 139px;
  background-image: url(../../_assets/img/page/page_bg_02.jpg);
  background-repeat: no-repeat;
  background-position: center;
 background-size: cover;
  text-align: center;
  @media #{$sp} {
    background-image: url(../../_assets/img/page/page_bg_02_sp.jpg);
    background-repeat: repeat-y;
    background-size: contain;
  }
  .about-profile__title {
    img {
      width: 152px;
      height: 63px;
    }
  }
  .about-profile__name {
    margin-top: 80px;
    img {
      width: 101px;
      height: 44px;
    }
  }
  .about-profile__comments {
    margin-top: 67px;
    padding: 0 5%;
    img {
      width: 100%;
      max-width: 791px;
      height: auto;
    }
  }
}

.about-trigger {
  width: 100%;
  height: auto;
  padding-top: 94px;
  padding-bottom: 183px;
  background-image: url(../../_assets/img/page/page_bg_01.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;
  .about-trigger__title {
    img {
      width: 152px;
      height: 63px;
    }
  }
  .about-trigger__image {
    margin-top: 30px;
    img {
      width: 298px;
      height: 199px;
    }
  }
  .about-trigger__text {
    margin-top: 24px;
    padding: 0 5%;
    img {
      width: 100%;
      max-width: 748px;
      height: auto;
    }
  }
}
