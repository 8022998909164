.sec-service {
	.sec-service__head {
		padding-top: 47px;
		padding-bottom: 58px;
		text-align: center;
		background-color: #ddddf3;
	}
	.sec-service__middle {
		width: 100%;
		height: 220px;
		content: "";
		top: 0;
		right: 0;
		background-image: url(../../_assets/img/index/sec_service_bg.jpg);
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		padding-top: 50px;
		.sec-service__middle__title {
			position: relative;
			width: 320px;
			padding-left: 100px;
			margin: 0 auto;
			color: #fff;
			display: flex;
			flex-direction: column;
			@media #{$sp}{
				padding-left: 73px;
			}
			.head {
				font-size: 2.5rem;
				font-family: $aot101;
				font-weight: 400;
				font-style: normal;
				line-height: 1;
				letter-spacing: -1px;
				@media #{$sp}{
					font-size: 2.2rem;
					letter-spacing: 0;
				}
			}
			.bottom {
				margin-top: 8px;
				font-size: 2.1rem;
				font-family: $aot101;
				font-weight: 400;
				font-style: normal;
				line-height: 1;
				letter-spacing: -7px;
				@media #{$sp}{
					font-size: 1.8rem;
					letter-spacing: 0;
				}
			}
		}
		.sec-service__middle__text {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 179px;
			margin: 33px auto 0;
			color: #fff;
			@media #{$sp}{
				margin: 20px auto 0;
			}
			.head {
				font-size: 2.1rem;
				font-family: $aot101;
				font-weight: 400;
				font-style: normal;
				line-height: 1;
			}
			.bottom {
				margin-top: 4px;
				font-size: 2.3rem;
				font-family: $vendetta;
				font-weight: 300;
				line-height: 1;
				font-style: italic;
			}
		}
		.sec-service__middle__title:before {
			position: absolute;
			content: "";
			top: 50%;
			left: 0;
			width: 84px;
			height: 53px;
			background-image: url(../../_assets/img/index/icon_sec_service_middle.svg);
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			transform: translateY(-50%);
			@media #{$sp}{
				width: 50px;
				height: 33px;
			}
		}
	}
	.sec-service__bottom {
		padding: 108px 0 85px;
		background-color: #ddddf3;
		@media #{$sp}{
			padding-left: 15px;
			padding-right: 15px;
		}
		.sec-service__bottom__contents {
			width: 950px;
			margin: 0 auto;
			display: flex;
			@media #{$sp}{
				width: 100%;
				flex-wrap: wrap;
			}
			.sec-service__bottom__main {
				width: 430px;
				height: auto;
				margin-right: 90px;
				@media #{$sp}{
					width: 100%;
					margin-right: 0;
				}
				.sec-service__bottom__title {
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 150px;
					height: 39px;
					font-size: 1.5rem;
					line-height: 28px;
					letter-spacing: 0.1em;
					font-family: $aot101;
					background-color: #10124d;
					color: #fff;
					@media #{$sp}{
						margin: 0 auto;
					}
				}
				.sec-service__bottom__title:before {
					position: absolute;
					content: "";
					top: -23px;
					left: 50%;
					width: 30px;
					height: 22px;
					background-image: url(../../_assets/img/index/icon_service_bottom.svg);
					background-repeat: no-repeat;
					background-position: center;
					background-size: cover;
					transform: translateX(-50%);
				}
				.sec-service__bottom__text {
					font-size: 1.5rem;
					line-height: 28px;
					letter-spacing: 0;
					font-family: $aot101;
					color: #000;
					@media #{$sp}{
						font-size: 1.4rem;
					}
				}
				.sec-service__bottom__box01 {
					.sec-service__bottom__text {
						margin-top: 14px;
					}
				}
				.sec-service__bottom__box02{
					margin-top: 65px;
					@media #{$sp}{
						padding-left: 15px;
						padding-right: 15px;
					}
					.sec-service__bottom__text {
						margin-top: 32px;
						@media #{$sp}{
							margin-top: 20px;
						}
						&.-first {
							margin-top: 11px;
						}
					}
				}
			}
			.sec-service__bottom__sub {
				width: 430px;
				height: auto;
				padding-top: 60px;
				@media #{$sp}{
					width: 100%;
				}
				.sec-service__bottom__btn-wrap {
					margin-top: 57px;
					.sec-service__bottom__btn {
						position: relative;
						display: block;
						width: 398px;
						height: 139px;
						margin: 0 auto;
						padding: 24px 22px 0 48px;
						border: 1px solid #161866;
						font-size: 1.5rem;
						line-height: 28px;
						letter-spacing: 0;
						font-family: $aot101;
						color: #000;
						text-align: center;
						transition: 1s;
						@media #{$sp}{
							width: 100%;
							font-size: 1.2rem;

						}
					}
					.sec-service__bottom__btn:hover {
						background-color: #fff;
					}
					.sec-service__bottom__btn:hover:after {
						left: 70%;
					}
					.sec-service__bottom__btn:before {
						position: absolute;
						content: "";
						top: 0;
						left: 0;
						width: 54px;
						height: 54px;
						background-image: url(../../_assets/img/index/icon_triangle.svg);
						background-repeat: no-repeat;
						background-position: center;
						background-size: cover;
					}
					.sec-service__bottom__btn:after {
						position: absolute;
						content: "";
						bottom: 19px;
						left: 50%;
						width: 128px;
						height: 33px;
						background-image: url(../../_assets/img/index/icon_link_click.svg);
						background-repeat: no-repeat;
						background-position: center;
						background-size: cover;
						transform: translateX(-50%);
						transition: 0.7s;
						@media #{$sp}{
							width: 112px;
							height: 28px;
						}
					}
				}
			}
		}
	}
}