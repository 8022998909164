.widget{
	margin-bottom: 40px;
	&.recent-posts {

	}
	.widget__title {
		margin-bottom: 13px;
		color: #e40c34;
		font-size: 2.2rem;
		line-height: 1.2;
	}
	.widget__list{
		padding-left: 40px;
		margin-bottom: 10px;
		li {
			margin-bottom: 5px;
			list-style: disc;
			a{
				color: #555;
			}
		}
		&.-no-space{
			padding-left: 0;
			margin-top: 40px;
		}
		.categories {
			list-style: none;
				margin-bottom: 13px;
				color: #e40c34;
				font-size: 2.2rem;
				line-height: 1.2;
				font-weight: bold;
				ul{
					margin-top: 12px;
					padding-left: 40px;
					li.cat-item{
						margin-bottom: 5px;
						font-size: 1.5rem;
						color: #585858;
						line-height: 2.6rem;
						font-weight: normal;
					}
				}
			}
	}
}

