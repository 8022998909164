.sec-contact {
	padding-top: 74px;
	padding-bottom: 73px;
	text-align: center;
	content: "";
	top: 0;
	right: 0;
	width: 100%;
	background-image: url(../../_assets/img/index/sec_contact_bg.jpg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	background-attachment:fixed;
	.sec-title {
		color: #fff;
		border-bottom: 1px solid #fff;
	}
	.sec-sub-title {
		color: #fff;
	}
	.sec-contact__main {
		width: 355px;
		margin: 69px auto 0;
	}
}