.sec-course {
  .sec-course__head {
    height: 114px;
    background-color: #ddddf3;
    display: flex;
    justify-content: center;
    .sec-course__head__title {
      position: relative;
      width: 135px;
      height: 54px;
      margin-top: 16px;
      font-family: $aot31;
      font-size: 2.1rem;
      color: #10124d;
      span {
        font-size: 3.6rem;
        color: #9a111f;
        font-family: $kozuka-micho;
        font-style: normal;
        font-weight: 700;
      }
    }
    .sec-course__head__title:after {
      position: absolute;
      bottom: -30px;
      left: 50%;
      content: "course";
      transform: translateX(-50%);
      color: #10124d;
      font-size: 2.3rem;
      font-family: $vendetta;
      font-weight: normal;

    }
  }
  .sec-course__main {
    padding-top: 115px;
    padding-bottom: 110px;
    @media #{$sp}{
      padding-top: 40px;
      padding-left: 15px;
      padding-right: 15px;
    }
      .sec-course__main__title {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 142px;
        height: 39px;
        margin: 114px auto 0;
        font-size: 1.8rem;
        line-height: 21px;
        letter-spacing: 0.1em;
        font-family: a-otf-futo-min-a101-pr6n, serif;
        background-color: #10124d;
        color: #fff;
        @media #{$sp}{
          margin: 80px auto 0;
        }
        &.-first {
          margin: 0 auto;
        }
      }
      .sec-course__main__title:before{
      position: absolute;
      content: "";
      top: -23px;
      left: 50%;
      width: 30px;
      height: 22px;
      background-image: url(../../_assets/img/index/icon_service_bottom.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
    }
    .sec-course__main__copy {
      margin-top: 17.5px;
      margin-top: calc(21px - ((21px - 14px)/2));
      font-size: 1.4rem;
      line-height: 21px;
      letter-spacing: 0.01em;
      font-family: $aot101;
      font-weight: 400;
      font-style: normal;
      text-align: center;
      color: $color_accent;
      @media #{$sp}{
        text-align: left;
        padding-left: 3px;
        padding-right: 3px;
      }

    }
    .sec-course__main__contents {
      margin: 23.5px auto 0;
      margin: calc(27px - ((21px - 14px)/2)) auto 0;
      padding: 30px 58px 30px;
      width: 779px;
      height: 462px;
      background-image: url(../../_assets/img/page/course_bg.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      @media #{$sp}{
        background-image: none;
        width: 100%;
        height: auto;
        padding: 20px;
        border: 3px double $color_accent;
      }
       &.-large {
         background-image: url(../../_assets/img/page/course_bg_large.png);
         height: 529px;
         @media #{$sp}{
           height: auto;
           background-image: none;
         }
    }
      .sec-course__main__sub-title {
        margin-top: 29.5px;
        margin-top: calc(34px - ((21px - 14px)/2) - 1px);
        display: inline-block;
        padding-bottom: 2px;
        font-size: 1.4rem;
        line-height: 16px;
        font-family: $aot101;
        font-weight: 400;
        font-style: normal;
        color: #444444;
        border-bottom: 1px solid $color_accent;
        @media #{$sp}{
          margin-top: 40px;
        }
        &.-first {
          margin-top: 0;
        }
      }
      .sec-course__main__text {
        margin-top: 10px;
        font-size: 1.4rem;
        line-height: 21px;
        font-family: $aot101;
        font-weight: 400;
        font-style: normal;
        color: #444444;
        letter-spacing: 0;
      }
    }
  }
}
.sec-page__head {
  height: 114px;
  background-color: #ddddf3;
  display: flex;
  justify-content: center;
  .sec-page__head__title {
    position: relative;
    width: 135px;
    height: 54px;
    margin-top: 16px;
    font-family: $aot31;
    font-size: 2.1rem;
    color: #10124d;
    &.-flow {
      margin-top: 32px;
      width: 200px;
      height: 40px;
    }
    &.-flow:after {
      content: "Flow";
    }
    &.-support {
      margin-top: 32px;
      width: 220px;
      height: 40px;
    }
    &.-support:after {
      content: "Support";
    }
    &.-academy {
      margin-top: 32px;
      width: 305px;
      height: 40px;
    }
    &.-academy:after {
      content: "Academy";
    }
    &.-end {
      margin-top: 32px;
      width: 66px;
      height: 32px;
    }
    &.-end:after {
      content: "Message";
    }
    span {
      font-size: 3.6rem;
      color: #9a111f;
      font-family: $kozuka-micho;
      font-style: normal;
      font-weight: 700;
    }
  }
  .sec-page__head__title:after {
    position: absolute;
    bottom: -30px;
    left: 50%;
    content: "course";
    transform: translateX(-50%);
    color: #10124d;
    font-size: 2.3rem;
    font-family: $vendetta;
    font-weight: normal;

  }
}


.sec-flow {
  .sec-flow__main {
    padding-top: 53px;
    padding-bottom: 68px;
    @media #{$sp}{
      padding-left: 15px;
      padding-right: 15px;
    }
    .sec-flow__main__copy {
      font-size: 1.4rem;
      line-height: 21px;
      letter-spacing: 0.01em;
      font-family: $aot101;
      font-weight: 400;
      font-style: normal;
      text-align: center;
      color: $color_accent;
      @media #{$sp}{
        text-align: left;
      }
    }
    .sec-flow__main__list {
      display: flex;
      flex-wrap: wrap;
      width: 816px;
      margin: 84px auto 0;
      @media #{$sp}{
        width: 100%;
        margin: 40px auto 0;
      }
      .sec-flow__main__list-items {
        width: 407px;
        height: 360px;
        border-left: 1px solid $color_accent;
        @media #{$sp}{
          width: 100%;
          border: none;
          height: auto;
          margin-top: 42px;
        }
        .sec-flow__main__sub-title {
          margin-top: 17px;
          font-size: 1.8rem;
          line-height: 21px;
          letter-spacing: 0.01em;
          font-family: $aot31;
          font-weight: 400;
          font-style: normal;
          text-align: center;
          color: $color_accent;
        }
        .sec-flow__main__image {
          width: 100%;
          height: 176px;
          display: flex;
          justify-content: center;
          align-items: center;
          &.-flow01 {
            img {
              width: 180px;
              height: 115px;
            }
          }
          &.-flow02 {
            img {
              width: 117px;
              height: 107px;
            }
          }
          &.-flow03 {
            img {
              width: 147px;
              height: 100px;
            }
          }
          &.-flow04 {
            img {
              width: 157px;
              height: 116px;
            }
          }
        }
        .sec-flow__main__text {
          margin: 0 auto;
          width: 298px;
          font-size: 1.4rem;
          line-height: 21px;
          letter-spacing: 0.01em;
          font-family: $aot101;
          font-weight: 400;
          font-style: normal;
          text-align: center;
          color: $color_accent;
          text-align: center;
        }
      }
      .sec-flow__main__list-items:nth-child(2n){
      border-right: 1px solid $color_accent;
        @media #{$sp}{
          border: none;
        }
    }
      .sec-flow__main__list-items:nth-child(3n) {
        margin-top: 42px;
      }
      .sec-flow__main__list-items:nth-child(4n) {
        margin-top: 42px;
        border-right: 1px solid $color_accent;
        @media #{$sp}{
          border: none;
        }
      }
    }
  }
}
.sec-support {
.sec-support__main {
  padding-top: 70px;
  padding-bottom: 85px;
  @media #{$sp}{
    padding-top: 0;
  }
  .sec-support__contents{
    width: 660px;
    margin: 75px auto 0;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    @media #{$sp}{
      width: 100%;
      flex-direction: column-reverse;
      padding-left: 15px;
      padding-right: 15px;
    }
    &.-first {
      margin-top: 0;
    }
    .sec-support__contents__main {
      width: 497px;
      width: calc(100% - 130px - 33px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media #{$sp}{
        width: 100%;
        margin-top: 30px;

      }
      .sec-support__contents__sub-title {
        position: relative;
        padding-left: 37px;
        font-size: 1.8rem;
        line-height: 21px;
        font-family: $aot31;
        font-weight: 400;
        font-style: normal;
        color: $color_accent;
      }
      .sec-support__contents__sub-title:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 26px;
        height: 21px;
        background-image: url(../../_assets/img/page/icon_arrow_title.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

      }
      .sec-support__contents__text {
        margin-top: 20px;
        font-size: 1.4rem;
        line-height: 21px;
        font-family: $aot101;
        font-weight: 400;
        font-style: normal;
        color: $color_accent;
      }

    }
    .sec-support__contents__sub {
      margin-right: 33px;
      width: 130px;
      height: 130px;
      @media #{$sp}{
        margin-right: 0;
      }
    }
  }
}
}
.sec-academy {
  .sec-academy__middle {
    width: 100%;
    height: 122px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    line-height: 21px;
    color: $color_accent;
    font-family: $aot101;
    @media #{$sp}{
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .bottom-panel {
    display: flex;
    @media #{$sp}{
      flex-wrap: wrap;
    }
    .bottom-panel__left {
      width: 50%;
      height: 610px;
      background: #707194;
      display: flex;
      justify-content: flex-end;
      @media #{$sp}{
        width: 100%;
        height: auto;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 50px;
      }
      .bottom-panel__inner {
        width: 500px;
        height: 100%;
        padding-top: 50px;
        padding-left: 5px;
      }
    }
    .bottom-panel__right {
      width: 50%;
      height: 610px;
      background: #10124d;
      display: flex;
      padding-top: 50px;
      padding-left: 45px;
      @media #{$sp}{
        width: 100%;
        height: auto;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 50px;
      }
      .bottom-panel__inner {
        width: 500px;
        height: 100%;
      }
    }
    .bottom-panel__title {
      position: relative;
      font-size: 2.4rem;
      line-height: 31px;
      font-family: $aot31;
      font-weight: 600;
      font-style: normal;
      color: #fff;
      letter-spacing: 0;
      &.-port01:after {
        width: 211px;
        height: 11px;
        background-image: url(../../_assets/img/page/academy_arrow_01.svg);
      }
      &.-port02:after {
        width: 296px;
        height: 11px;
        background-image: url(../../_assets/img/page/academy_arrow_02.svg);
      }
    }
    .bottom-panel__title:after {
      position: absolute;
      content: "";
      left: 0;
      bottom: -7px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    .bottom-panel__text {
      margin-top: 18px;
      font-size: 1.4rem;
      line-height: 21px;
      font-family: $aot101;
      font-weight: 400;
      font-style: normal;
      color: #fff;
      &.-money {
        margin-top: 38px;
      }
      .money {
        font-size: 18px;
      }
      .money-big {
        font-size: 24px;
      }
      &.-mt10 {
        margin-top: 10px;
      }
      &.-mt86 {
        margin-top: 86px;
      }
    }
    .bottom-panel__sub-title {
      margin-top: 37px;
      font-size: 1.4rem;
      line-height: 21px;
      font-family: $aot101;
      font-weight: 400;
      font-style: normal;
      color: #fff;
      &.-plus {
        margin-top: 68px;
      }
    }
  }
}
.sec-info {
  padding-top: 50px;
  padding-bottom: 71px;
  text-align: center;
  @media #{$sp}{
    padding-left: 15px;
    padding-right: 15px;
  }
  .sec-info__title {
    display: inline-block;
    padding: 0 15px;
    font-size: 2.6rem;
    line-height: 31px;
    font-family: $aot31;
    font-weight: 600;
    font-style: normal;
    color: $color_accent;
    border-right: 1px solid $color_accent;
    border-left: 1px solid $color_accent
  }
  .sec-info__btn-wrap {
    width: 520px;
    margin: 55px auto 55px;
    display: flex;
    justify-content: space-between;
    @media #{$sp}{
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .sec-info__btn {
      width: 216px;
      height: 81px;
      @media #{$sp}{
        width: 100%;
      }
      a {
        font-size: 1.8rem;
        line-height: 21px;
        font-family: $aot31;
        font-weight: 600;
        font-style: normal;
        color: #fff;
        letter-spacing: 0;
        transition: 1s;
      }
      a:hover {
        opacity: 0.7;
      }
      .-part01 {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #707194;
      }
      .-part02 {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $color_accent;
        @media #{$sp}{
          margin-top: 20px;
        }
      }
    }
  }
  .sec-info__text {
    margin-top: 23px;
    font-size: 1.4rem;
    line-height: 21px;
    font-family: $aot101;
    font-weight: 400;
    font-style: normal;
    color: $color_accent;
    @media #{$sp}{
      text-align: left;
    }
    &.-first {
      margin-top: 50px;
    }
  }
}
.sec-end {
  padding-bottom: 82.5px;
  .sec-end__main {
    position: relative;
    padding-top: 64px;
    text-align: center;
    @media #{$sp}{
      text-align: left;
        padding-left: 15px;
        padding-right: 15px;
    }
    .sec-end__text {
      margin-top: 20px;
      font-size: 1.4rem;
      line-height: 21px;
      font-family: $aot101;
      font-weight: 400;
      font-style: normal;
      color: $color_accent;
      &.-first {
        margin-top: 0;
      }
    }
    .sec-end__name {
      margin-top: 20px;
      font-size: 1.8rem;
      line-height: 45px;
      font-family: $aot101;
      font-weight: 400;
      font-style: normal;
      color: #000;
      @media #{$sp}{
        text-align: right;
      }
    }
  }
  .sec-end__main:after {
    position: absolute;
    content: "";
    top: 40px;
    left: 50%;
    z-index: -1;
    width: 236px;
    height: 293px;
    background-image: url(../../_assets/img/page/end_bg.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transform: translateX(-50%);
  }
}