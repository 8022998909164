.label-box {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 28px;
	@media #{$sp} {
		flex-direction: column;
		margin-top: 32px;
	}
	&.-first {
		margin-top: 0;
	}
	.label-box__title {
		width: 90px;
		text-align: center;
		line-height: 1;
		font-family: $agency;
		color: #fff;
		@media #{$sp} {
			width: 100%;
			padding-right: 0;
			text-align: left;
			min-height: auto;
		}
		&.-center {
			padding-top: 7px;
			@media #{$sp} {
				padding-top: 0;
			}
		}
		&.-f-center {
			display: flex;
			align-items: center;
		}
		label {
			font-size: 2.0rem;
		}
	}
	.label-box__input {
		width: 263px;
		height:auto;
		@media #{$sp} {
			margin-top: 7px;
			width: 100%;
		}
		input {
			display: block;
		}
		textarea {
			background: #f2f6f7;
			border:none;
			height: 105px;
		}
		textarea:focus {
			background-color: #FFFDE7;
		}
	}
}