.btn-view{
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	height: 50px;
	border: 2px solid #000;
	font-size: 1.6rem;
	line-height: 1;
	font-family: $roboto-slab;
	justify-content: center;
	color: #000;
	transition: 3s;
}
.btn-view:before {
	position: absolute;
	content: "";
	background-image: url("/../_assets/img/about/ion-change-circle.svg");
	background-repeat: no-repeat;
	background-position: center;
	right: 15px;
	top: 50%;
	width: 20px;
	height: 20px;
	transform: translateY(-50%);
}


//form-btn
.btn-wrap {
	&.-agreement-option {
		padding-top: 75px;
		padding-bottom: 103px;
		@media #{$sp} {
			padding-bottom: 50px;
		}
	}
	&.-input-option {
		padding-top: 43px;
		@media #{$sp} {
			padding-top: 50px;
		}

	}
	.form-btn {
		width: 150px;
		height: 38px;
		font-size: 1.4rem;
		line-height: 1;
		letter-spacing: 0.12em;
		text-align: center;
		color: #fff;
		border: 1px solid #fff;
		background:none;
		font-family: $agency;
		transition: 0.7s;
		@media #{$sp} {
			height: 38px;
		}
	}
}
.btn-wrap-list {
	margin-top: 98px;
	display: flex;
	justify-content: space-between;
	@media #{$sp} {
		flex-wrap: wrap;
		flex-direction: column;
	}
	.btn-wrap-list__items:last-child {
		@media #{$sp} {
			margin-top: 20px;
		}
	}
	.btn-wrap-list__items input{
		width: 385px;
		height: 80px;
		font-size: 1.8rem;
		line-height: 1;
		letter-spacing: 0.04em;
		text-align: center;
		@media #{$sp} {
			display: block;
			width: 100%;
			height: 60px;
		}
		&.btn-before {
			color: #000;
			border: 1px solid #000;
		}
		&.btn-after {
			color: #fff;
			background-color: #b60005;
			border: 1px solid #b60005;

		}
	}
}




// baseはmaterializecssのbtn
// 他のclassで上書きする
a.button {
	background-color: #1ae29c;
	display: block;
	color: #fff;
	font-weight: bold;
	border-radius: 39px;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	box-shadow: 0 2px 8px 0 #1ae29c;
	max-width: 315px;
	height: 60px;
	font-size: 1.8rem;
	padding-top: 21px;
	box-sizing: border-box;
	border: solid 2px transparent;
	line-height: 1;
	@media #{$sp} {
		font-size: 1.6rem;
	}
}
a.button:hover {
	background-color: #fff;
	color: #1ae29c;
	box-shadow: 0 2px 6px 0 rgba(26,226,156,0.4);
}

a.button-small{
	max-width: 150px;
	margin-top: 47px;
	font-size: 1.3rem;
	height: 40px;
	padding-top: 12px;
	box-shadow: 0 2px 6px 0 rgba(255,4,4,0.3);
	position: relative;
	background-color: #fff;
	color: #f1464a;
	border: solid 1px #f1464a;
	display: block;
	font-weight: bold;
	border-radius: 39px;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	line-height: 1;
	@media #{$sp} {
		margin-top: 12px;
	}
}
a.button-small:after {
	position: absolute;
	content: "";
	width: .5em;
	height: .5em;
	margin-left: 1em;
	display: inline-block;
	margin-top: auto;
	margin-bottom: auto;
	vertical-align: middle;
	top: 0;
	bottom: 0;
	border-right: solid 2px #f1464a;
	border-top: solid 2px #f1464a;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
	-moz-box-sizing: content-box;
	-webkit-box-sizing: content-box;
	box-sizing: content-box;
}
a.button-small:hover{
	box-shadow: 0 4px 12px 0 rgba(255,4,4,0.6);
}


a.btn {
	&:link ,&:visited {
		color: #fff;
	}
	&:hover {
		text-decoration: none;
	}
}

.btn {
	
	i{
		font-size: 1.5rem;
		margin-right: 4px;
	}

	&.-big {
		display: flex;
		flex-flow:column;
		justify-content: center;
		width: 100%;
		min-width: 196px;
		//max-width: 640px;
		min-height: 94px;
		font-weight: bold;
		font-size: 1.8rem;
		text-align: center;
		.small {
			font-size: 1.4rem;
		}
		strong {
			font-size: 2.2rem;
		}
	}
	&.-long {
		width: 570px;
	}
	&.-w100p {
		width: 100%;
	}
	&.-shadowoff {
		box-shadow: 0 0 0 0 rgba(#000,0);
		transition: box-shadow .2s ease-out;
		&:hover {
			@extend .z-depth-1-half;
		}
	}

	&.-round {
		height: auto;
		padding: 0.5em 2em;
		font-size: 1.2rem;
		font-weight: bold;
		line-height: 1.4;
		border-radius: 100em;
	}

	&.-blue {
		background-color: $color_primary !important;
	}
	&.-white {
		color: #333 !important;
		background-color: #fff !important;
	}

	&.-googleplus {
		background-color: #DB4437 !important;
		&:hover {
			background-color: lighten(#DB4437,10%) !important;
		}
	}
	&.-amazon {
		background-color: #FF9900 !important;
		&:hover {
			background-color: lighten(#FF9900,10%) !important;
		}
	}
	&.-facebook {
		 background-color: #3B5998 !important;
		&:hover {
			background-color: lighten(#3B5998, 10%) !important;
		}
	}


	&.-submit {
		padding: 0.55em 2em 0.5em;
		font-size: 1.7rem;
		box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.2);
		i {
			position: relative;
			left: 0;
			top: 1px;
			margin-top: 0;
			margin-right: 0.2em;
			font-size: 2rem;
			transition: left 0.2s;
		}
		&:hover {
			box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.2);
			border-color: $color_btn_bg;
			i {
				left: 0.2em;
			}
		}
		@media #{$sp} {

		}
	}
}
