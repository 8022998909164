.gnav {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
  @media #{$sp}{
    display: none;
    background-color: #fff;
    position: fixed;
    top: -60px;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 70px;
  }
  li {
    margin-left: 74px;
    @media #{$sp}{
      margin-left: 0;
      text-align: center;
      margin-top: 20px;
    }
    .gnav__items {
      position: relative;
      color: $color_accent;
      font-size: 1.8rem;
      font-family: $agency;
      font-weight: 400;
      font-style: normal;
      line-height: 1;
      letter-spacing: 0;
      @media #{$sp}{
        border-bottom: 1px solid $color_accent;
      }
    }
    .gnav__items:after {
      position: absolute;
      bottom: -3px;
      left: 0;
      content: "";
      width: 0;
      height: 1px;
      background-color: $color_accent;
      opacity: 0;
      transition: 0.3s;
      @media #{$sp}{
        display: none;
      }
    }
    .gnav__items:hover:after {
      left: 0;
      width: 100%;
      opacity: 1;
    }
  }
  li:first-child {
    margin-left: 0;
  }
}