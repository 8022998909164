.main-visual {
  position: relative;
  content: "";
  top: 0;
  right: 0;
  margin-top: 94px;
  width: 100%;
  height: 648px;
  background-image: url(../../_assets/img/index/main_bg.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @media #{$sp}{
    margin-top: 59px !important;
    height: calc(100vh - 59px);
  }
  .main-visual__copy-wrap {
    padding-top: 214px;
    text-align: center;
    @media #{$sp}{
      padding-top: 150px;
    }
    .main-visual__copy01 {
      font-size: 64px;
      line-height: 1;
      font-family: $agency;
      font-weight: 400;
      font-style: normal;
      color: #fff;
      @media #{$sp}{
        font-size: 40px;
      }
    }
    .main-visual__copy02 {
      margin-top: 10px;
      font-size: 5.4rem;
      font-family: $sakkal;
      line-height: 1;
      font-weight: bold;
      color: #fff;
      @media #{$sp}{
        font-size: 30px;
      }
    }
  }

  .main-visual__text {
    margin-top: 39px;
    color: #fff;
    text-align: center;
    font-family: $aot31;
    font-size: 1.5rem;
    line-height: 18px;
    letter-spacing: 0.1em;
    font-weight: 600;
    font-style: normal;
    @media #{$sp}{
      padding: 0 15px;
    }

  }
  .main-visual__scroll {
    position: absolute;
    width: 43px;
    height: 94px;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    img {
      width: 100%;
      animation-fill-mode:both;
      animation-duration:2s;
      animation-iteration-count: infinite;
      animation-name: move;
      visibility: visible !important;
      @-webkit-keyframes move {
        0% { opacity: 0; -webkit-transform: translateY(-30px); }
        100% { opacity: 1; -webkit-transform: translateY(0); }
      }
      @keyframes move {
        0% { opacity: 0; -webkit-transform: translateY(-30px); -ms-transform: translateY(-30px); transform: translateY(-30px); }
        100% { opacity: 1; -webkit-transform: translateY(0); -ms-transform: translateY(0); transform: translateY(0); }
      }
    }
  }
}


