// 1カラム、グローバルメニューなしのコンテンツ(ログインなど）
.l-single {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	min-height: 100vh;
	background: $color_blue_gray_pale;

	&__inner {
		width: 100%;
		max-width: 600px;
	}
	@media #{$sp} {
		display: block;
		background: #fff;
		&__inner {
			width: 100%;
			max-width: none;
		}
	}

	.header {
		position: relative;
		display: block;
		background: transparent;
		@media #{$pc} {
			.l-wrapper {
				display: block;
				padding: 36px 0;
				height: auto;
				box-shadow:none;
			}
			&__main {
				width: 100%;
				text-align: center;
			}
			&__logo {
				width: auto;
				height: auto;
				a {
					display: inline-flex;
					height: auto;
					img {
						width: 132px;
					}
				}
			}
		}
		@media #{$sp} {
			.l-wrapper {
				box-shadow:none;
			}
		}
	}
	.footer {
		margin: 0;
		padding: 0 0 100px;
		text-align: center;
		color: #999;
	}

	&__main {
		padding: 0;
		&__inner {
			padding: 36px 90px;
			background: #fff;
			border-radius: 4px;
		}
		&__title {
			margin: 0 0 0.8em;
			font-size: 2.6rem;
			text-align: center;
			line-height: 1.2;
		}

		@media #{$lg-down} {
			padding-top: 0;
		}
		@media #{$sp} {
			padding-top: 0;
			&__inner {
				margin: 0 auto;
				padding: 18px 15px;
				max-width: 500px;
				//background: transparent;
				border-radius: 0;
			}
			&__title {
				margin: 0 0 1.4em;
				font-size: 2rem;
				line-height: 1.4;
			}
		}

	}

	&__footer {
		margin: 1em 0;
		font-size: 1.3rem;
		text-align: center;
	}
}
