
.hum-wrap {
  display: none;
  @media #{$sp} {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    width: 60px;
    height: 60px;
    z-index: 100000;
    .hum {
      width: 60px;
      height: 60px;
      background: #10124d;
      position: relative;
      border: none;
      z-index: 100000;
      .menu-line {
        position: absolute;
        background: #FFF;
        width: 32px;
        height: 4px;
        border-radius: 2px;
        left: 0;
        right: 0;
        margin: auto;
        -webkit-transition: .4s;
        transition: .4s;
        z-index: 100000;
      }
      .menu-line:nth-child(1) {
        top: 15px;
      }
      .menu-line:nth-child(2) {
        top: 24px;
      }
      .menu-line:nth-child(3) {
        top: 33px;
      }
      .txt {
        position: absolute;
        right: 0;
        left: 0;
        display: block;
        bottom: 5px;
        margin: auto;
        line-height: 1;
        font-size: 13px;
        color: #FFF;
        font-weight: bold;
        text-align: center;
        font-family: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', Meiryo, メイリオ, Osaka, 'MS PGothic', arial, helvetica, sans-serif;
      }
      .is-open {
        display: block;
      }
      .is-close {
        display: none;
      }
      &.active {
        .menu-line:nth-child(1) {
          top: 24px;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
        }
        .menu-line:nth-child(2) {
          left: 15px;
          opacity: 0;
        }
        .menu-line:nth-child(3) {
          top: 24px;
          -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }
      }
    }
  }
}

.open {
  .hum-wrap {
    .hum {
      .is-open {
        display: none;
      }
      .is-close {
        display: block;
      }
    }
  }
  /*
  .gnav {
    display: block;
  }
  */
}