.sec-message {
	padding-top: 57px;
	padding-bottom: 63px;
	background: #ecedf3;
	text-align: center;
	@media #{$sp}{
		padding-left: 15px;
		padding-right: 15px;
	}
	.sec-message__title {
		display: inline-block;
		padding-bottom: 14px;
		margin: 0 auto;
		font-size: 2.5rem;
		line-height: 31px;
		line-height: calc(31/25);
		font-family: $agency;
		font-weight: 400;
		font-style: normal;
		color: #10124d;
		border-bottom: 1px solid #10124d;
	}
	.sec-message__text {
		margin-top: 45px;
		font-size: 1.2rem;
		line-height: 28px;
		line-height: calc(28/12);
		letter-spacing: 0.6em;
		font-family: $aot101;
		font-weight: 400;
		font-style: normal;
		color: #000;
		text-align: center;
		@media #{$sp}{
			text-align: left;
		}
	}
}