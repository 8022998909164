.header {
  position: fixed;
  height: 94px;
  width: 100%;
  z-index: 9999;
  background: #fff;
  @media #{$sp}{
    height: 60px;
  }
  .header-wrap {
    width: 100%;
    height: 94px;
    display: flex;
    justify-content: space-between;
    @media #{$sp}{
      height: 60px;
    }
    .header__logo {
      margin-top: 30px;
      margin-left: 8px;
      width: 169px;
      height: 41px;
      @media #{$sp}{
        margin-top: 21px;
        width: 169px;
        height: auto;
      }
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
    .header__nav {
      width: 758px;
      height: auto;
      @media #{$sp}{
        width: 100%;
      }
    }
  }
  &.-page {
    width: 100%;
    height: 293px;
    padding-top: 0;
    background-image: url(../../_assets/img/header/heder_page_bg.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    @media #{$sp} {
      background-image: url(../../_assets/img/header/heder_page_bg_sp.jpg);
    }
  }

}