.slick-slider {
	z-index: 1001;
	.slick-arrow {

	}
}
.side-slide {
  height: 100%;
}
.flow-slide  {
  .slick-arrow {

  }
	.slick-slide {
		padding: 5px;
		height: 387px;

		img {
			border-radius: 10px;
			height: 100%;
			object-fit: cover;
		}
	}
}
//スマホ　スライドアローの調整
.slick-prev {
  @media #{$sp} {
    left: 1%;
  }
}
.slick-next {
  @media #{$sp} {
    right: 1%;
  }
}
.slick-prev, .slick-next {
  z-index: 10002;
  width: 30px;
  height: 30px;
}
.slick-prev:before, .slick-next:before {
  font-size: 30px;

}

//スライド後の動き
.slick-active {
  .inner-box__main {
    animation: slideFromRight 1.7s ease 0s 1 normal;
    @media #{$sp} {
      animation: none;
    }
  }
}

@keyframes slideFromRight {
  0%, 75% {
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

.slide-dots {
  text-align: center;
  li {
    display: inline-block;
    margin: 0 15px;
    button {
      position: relative;
      text-indent: -9999px;
      &:before {
        content: '☆';
        font-size: 20px;
        text-indent: 0px;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    &.slick-active {
      button:before {
        content: '★';
      }
    }
  }
}





