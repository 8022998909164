


.form-box {
  display: flex;
  padding-bottom: 15px;
  margin: 20px 0 15px;
  border-bottom: 1px dotted #fff;
  &.-last-part {
    margin: 20px 0 35px;
  }
  @media #{$sp} {
    flex-direction: column;
    border-bottom: none;
  }
  &.-other-type {
    border-bottom: none;
    padding-bottom: 0;
  }
  &.-bottom-large {
    margin-bottom: 40px;
  }
  .form-box__title {
    width: 308px;
    text-align: right;
    vertical-align: middle;
    padding: 0 86px 0 0;
    @media #{$sp} {
      width: 100%;
      text-align: left;
      margin-bottom: 5px;
    }
    &.-textarea {
      vertical-align: top;
    }
    label {
      font-size: 1.4rem;
      color: #fff;
      font-weight: bold;
    }
    &.-must-mark {
      label {
        position: relative;
      }
      label:after {
        position: absolute;
        content: "必須";
        top: 0;
        right: -60px;
        width: 42px;
        height: 17px;
        padding-top: 2px;
        color: #c30d23;
        text-align: center;
        border-radius: 8px;
        line-height: 1;
        font-size: 1.1rem;
        background-color: yellow;
      }
    }
  }
  .form-box__contents {
    width: calc(740px - 308px);
    @media #{$sp} {
      width: 100%;
    }
    .address {
      display: block;
      color: #fff;
      margin-bottom: 10px;
      font-size: 1.3rem;
      @media #{$sp} {
        margin-bottom: 5px;
      }
    }
    .td-text {
      color: #fff;
    }
    .addressInput {
      display: inline-block;
      width: 15%;
      margin: 0 10px 15px;
    }
    label.text {
      margin-right: 18px;
      font-size: 1.3rem;
      color: #fff;
    }
  }
}
[type="checkbox"]:not(:checked), [type="checkbox"]:checked, [type="radio"]:not(:checked), [type="radio"]:checked {
  opacity: 0 !important;
  display: none;
}
[type="checkbox"] + span:not(.lever):before, [type="checkbox"]:not(.filled-in) + span:not(.lever):after {
  width: 15px !important;
  height: 15px !important;
}
input[type="submit"]:focus, input[type="reset"]:focus, input[type="button"]:focus {
  background-color: #fff;
  color: #000;
}
textarea {
  background-color: #fff;
  min-height: 104px;
}
input[type=text]:not(.browser-default) {
  margin: 0;
  height: 22px;
}
input[type]:focus,
textarea:focus{
  background-color:#FFFDE7;
}

[type="checkbox"]:not(:checked), [type="checkbox"]:checked,
[type="radio"]:not(:checked), [type="radio"]:checked{
  position: static;
  opacity: 1;
  margin-right: 5px;
  pointer-events:auto;
}
.submit-contents {
  button {
    width: 244px;
    height: 42px;
    padding: 0;
    @media #{$sp} {
      width: 100%;
      height: 42px;
      border-radius: 0;
      -webkit-appearance: none;
      background-color: #fff;
      color: $primary-color;
    }
    &.gap {
      @media #{$sp} {
        margin-top: 10px;
      }
    }
  }
  input {
    width: 244px;
    padding: 11px 0;
    line-height: 1;
    font-size: 20px;
    color: $primary-color;
    font-weight: bold;
    @media #{$sp} {
      padding: 0;
    }
  }

}

