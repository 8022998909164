.paging {
	display: flex;
	justify-content: left;
	@media #{$sp} {
		flex-wrap: wrap;
	}

	&__box {
		.paging {
			margin: 0;
		}
	}

	& &__item{
		text-align: center;
		margin: 2px;
		padding: 0;
		font-size: 1.6rem;
		font-weight: 600;
		@media #{$sp} {
			margin: 4px;
			font-size: 1.3rem;
		}

		&:before {content: none;}

		&.-first{
		}
		&.-next{
		}
		a, span{
			display: flex;
			align-items: center;
			justify-content: center;
			width: 40px;
			height: 40px;
			box-sizing: border-box;
            color: $color_blue_gray_dark;
            background: #F6F8F9;
		}
		a{
			&:hover{
				background: $color_accent;
				text-decoration: none;
			}
		}
		span.current{
			color: #fff;
			background: #e6002d;
		}
	}
}


.paging__box {
	margin-top: 40px;
	text-align: center;
	.wp-pagenavi a, .wp-pagenavi span {
		border: none;
		padding: 3px 7px;
		transition: 0.3s;
	}
	.wp-pagenavi a.page:hover {
		background: #e6002d;
		color: #fff;
	}
}