//number+plus-heading
.heading-number {
	position: relative;
	padding-top: 32px;
	font-size: 2.4rem;
	line-height: 36px;
	line-height: calc(37/24);
	letter-spacing: 0.12em;
	text-align: center;
	font-weight: normal;
	@media #{$sp} {
		padding-top: 50px;
		text-align: left;
	}
	&.-part01:after {
		content: "01";
	}
	&.-part02:after {
		content: "02";
	}
	&.-part03:after {
		content: "03";
	}
}
.heading-number:after {
	position: absolute;
	top: 0;
	left: 49%;
	transform: translateX(-50%);
	font-family: $roboto-slab;
	color: $color_accent;
	font-size: 1.4rem;
	line-height: 1;
	letter-spacing: 0.08em;
}

//border-Plus heading
.heading-border-r {
	display: flex;
	align-items: center;
	font-size: 2.5rem;
	letter-spacing: 0.09em;
	font-family: $roboto;
	line-height: 30px;
	line-height: calc(30/26);
}
.heading-border-r:after {
	content: "";
	margin-top: 4px;
	border-top: 2px solid #000;
	flex-grow: 1;
	margin-left: 0.3rem;

}


//normal
.heading03 {
	padding-top: 103px;
	font-size: 3rem;
	line-height: 54px;
	line-height: calc(54/30);
	letter-spacing: 0.12em;
	text-align: center;
	font-weight: normal;
	@media #{$sp} {
		padding-top: 60px;
		font-size: 2.4rem;
		text-align: left;
	}
}

//qa-title
