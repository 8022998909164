.burger {
	position: absolute;
	top: 0;
	left: 0;
	height: 48px;
	width: 48px;
	cursor: pointer;
	background: transparent;
	border: none;
	&:focus {
		outline:none;
		background: transparent;
	}
	.burger__text {
		position: absolute;
		left:50%;
		transform: translateX(-50%);
		//transform: translateX(-50%) scale(0.8);
		bottom: 9px;
		font-family: Helvetica;
		font-size: 1rem;
		color: $color_blue_gray_dark;
	}
	&.open {
		//background-color: #555;
		.burger__text {
		}
	}

	.x,
	.y,
	.z {
		position: absolute;
		top: 0px; bottom: 0px;
		left: 14px;
		margin:0;
		height: 1px; width: 20px;
		background: $color_blue_gray_dark;
		transition: all 200ms ease-out;
	}
	$burger_y : 12px;
	$burger_m : 5px;
	.x{top: $burger_y;}
	.y{top: $burger_y + $burger_m;}
	.z{top: $burger_y + $burger_m*2;}
	.collapse{
		top: 18px;
		//background:#fff;
		transition: all 70ms ease-out;
	}

	.rotate30{
		transform: rotate(30deg);
		transition: all 50ms ease-out;
	}
	.rotate150{
		transform: rotate(150deg);
		transition: all 50ms ease-out;
	}

	.rotate45{
		transform: rotate(45deg);;
		transition: all 100ms ease-out;
	}
	.rotate135{
		transform: rotate(135deg);
		transition: all 100ms ease-out;
	}

	.navbar{height:73px;background:#385e97;}

	//.circle {
	//	border-radius: 50%;
	//	width: 0px;
	//	height: 0px;
	//	position:absolute;
	//	top: 24px;
	//	right: 24px;
	//	background: rgba(#000, 0.9);
	//	opacity:1;
	//	transition: all 300ms cubic-bezier(0.000, 0.995, 0.990, 1.000);
	//	&.expand {
	//		width: 250vh;
	//		height: 250vh;
	//		top: -125vh;
	//		right: -125vh;
	//		transition: all 400ms cubic-bezier(0.000, 0.995, 0.990, 1.000);
	//
	//	}
	//}
}
