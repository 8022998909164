
.sec-title-wrap {
  .sec-title {
    display: inline-block;
    padding-bottom: 8px;
    margin: 0 auto;
    font-size: 2.5rem;
    line-height: 1;
    letter-spacing: 0;
    font-family: $agency;
    font-weight: 400;
    font-style: normal;
    color: #10124d;
    border-bottom: 1px solid #10124d;
  }
  .sec-sub-title {
    margin-top: 10px;
    font-size: 1.5rem;
    line-height: 1;
    letter-spacing: 0.4em;
    font-family: $aotMiddle;
    color: #10124d;
  }
}

