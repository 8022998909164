.sec-about {
	text-align: center;
	padding-bottom: 65px;
	.sec-about__head{
		padding-top: 55px;
		padding-bottom: 58px;
		background-color: #ddddf3;
	}
	.sec-about__info {
		padding-top: 55px;
		p{
			margin-top: 25px;
			font-size: 1.8rem;
			line-height: 21px;
			line-height: calc(21/18);
			font-family: $aot101;
			font-weight: 400;
			font-style: normal;
			@media #{$sp}{
				margin-top: 18px;
				font-size: 1.4rem;

			}

		}
	}
	.sec-about__profile {
		margin-top: 75px;
		@media #{$sp}{
			margin-top: 45px;
		}
		.sec-about__profile__title {
			display: inline-block;
			padding: 0 12px;
			margin: 0 auto;
			font-size: 2.1rem;
			line-height: 31px;
			line-height: calc(21/18);
			font-family: $aot101;
			font-weight: 400;
			font-style: normal;
			color: #000;
			border-right: 1px solid #000;
			border-left: 1px solid #000;
			@media #{$sp}{
				font-size: 1.8rem;
			}
		}
		.sec-about__profile__content {
			margin: 65px auto 0;
			width: 550px;
			display: flex;
			flex-direction: row-reverse;
			@media #{$sp}{
				width: 100%;
				margin: 40px auto 0;
				flex-wrap: wrap;
				flex-direction: column-reverse;
			}
			.sec-about__profile__main {
				width: 265px;
				padding-top: 60px;
				padding-left: 17px;
				@media #{$sp}{
					width: 100%;
					padding-top: 30px;
				}
				.sec-about__profile__in-box {
					h4 {
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						color: #000;
						.head {
							position: relative;
							font-size: 2.7rem;
							line-height: 48px;
							line-height: calc(48/27);
							font-family: $aot101;
							font-weight: 400;
							font-style: normal;
							@media #{$sp}{
								font-size: 2.2rem;
							}
						}
						.head:after{
							position: absolute;
							content: "";
							background-color: #000;
							width: 19px;
							height: 2px;
							bottom: -5px;
							left: 0;
						}
						.middle {
							margin-top: 11px;
							font-size: 1.8rem;
							line-height: 31px;
							line-height: calc(31/18);
							font-family: $aot101;
							font-weight: 400;
							font-style: normal;
							@media #{$sp}{
								font-size: 1.6rem;
							}
						}
						.bottom {
							margin-top: 6px;
							font-size: 2.8rem;
							line-height: 45px;
							line-height: calc(28/45);
							font-family: $aot101;
							letter-spacing: 0.3em;
							font-weight: 400;
							font-style: normal;
							@media #{$sp}{
								font-size: 2.2rem;
							}
						}
					}
				}
			}
			.sec-about__profile__sub {
				width: 231px;
				height: auto;
				margin-right: 50px;
				@media #{$sp}{
					margin: 0 auto;
				}
			}
		}
		.sec-about__profile__bottom {
			margin-top: 46px;
			@media #{$sp}{
				padding-left: 17px;
			}
			p{
				font-size: 1.8rem;
				line-height: 31px;
				line-height: calc(31/18);
				font-family: $aot101;
				font-weight: 400;
				font-style: normal;
				color: #000;
				@media #{$sp}{
					font-size: 1.4rem;
					text-align: left;
				}
			}
		}
	}
}