// base layout
.l-body{
	//min-width: 1000px;
	overflow: hidden;
	width: 100%;
	&--mypage{
		background: #F8F8F8;
		//min-width: 960px;
	}

	//
	body.page-fixed & {
		padding-top: 49px;
	}
}

html.noscroll, html.noscroll body{
	overflow: hidden;
}

.l-header{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 1000;
}
.l-footer{
	position: relative;
}


.l-wrapper {
	box-sizing: border-box;
	max-width: 1000px;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	@media #{$lg-down} {
		max-width: none;
		padding-left: 3%;
		padding-right: 3%;
	}
	&.-w1191 {
		max-width: 1191px;
		@media #{$sp} {
			max-width: none;
			padding-left: 15px;
			padding-right: 15px;
		}
	}
	&.-w1180 {
		max-width: 1180px;
		@media #{$sp} {
			max-width: none;
			padding-left: 15px;
			padding-right: 15px;
		}
	}
	&.-w820 {
		max-width: 820px + 25*2px;
		@media #{$sp} {
			max-width: none;
		}
	}
	&.-full {
		max-width: 100%;
		padding-right: 0;
		padding-left: 0;
		@media #{$sp} {
			max-width: none;
		}
	}
}
/*
.l-visual {
	position: relative;
	width: 100%;
	min-width: 1100px;
	max-width: 1600px;
	margin-left: 4.75%;
	padding-left: 3.58%;
	overflow: hidden;
	img {
		width: 100%;
	}
}
*/


.l-inner {
	width: 908px;
	margin: 0 auto;
	@media #{$sp} {
		width: 100%;
	}
}
.l-inner-w870 {
	max-width: 870px;
	margin: 0 auto;
	@media #{$sp} {
		width: 100%;
	}
}
.l-inner-w648 {
	max-width: 648px;
	margin: 0 auto;
	@media #{$sp} {
		width: 100%;
	}
}
.l-main {
	//padding-top: 70px;
	//padding-bottom: 120px;
	@media #{$lg-down} {
		//padding-top: 45px;
		//padding-bottom: 50px;
	}
}

// full width
.l-full {
	margin:0 -200%;
	padding:0 200%;
}

.row {
	&.l-w100p {
		margin-right: -0.75rem;
		margin-left: -0.75rem;
	}
}

.l-content {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	margin-top: 40px;

	&__main {
		max-width: 700px;
		margin-bottom: 40px;
		@media #{$lg-down} {
			margin-right: 20px;

		}
	}
	&__sub {
		width: 341px;

	}
	@media #{$sp} {
		flex-wrap: wrap;
		&__main {
			width: 100%;
		}
		&__sub {
			width: 90%;
			margin: 0 auto;
			padding-top: 40px;
			border-top: 1px solid #ddd;
		}
	}
}

// flex layout
.l-flex{
	display: flex;
}
.l-flex-sb{
	display: flex;
	justify-content: space-between;
	@media #{$pc} {
		&--pc {
			display: flex;
			justify-content: space-between;
		}
	}
	@media #{$sp} {
		&--sp {
			display: flex;
			justify-content: space-between;
		}
	}
}

.l-flex {
	list-style: none;
	// 2カラム
	&.-col2 {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		@media #{$sp} {
			flex-direction: row;
			flex-wrap: wrap;
		}
		.l-flex__item {
			width: calc(50% - 15px);
			@media #{$sp} {
				width: 100%;
				margin-bottom: 30px;
			}
		}
	}
	// 3カラム
	&.-col3 {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		@media #{$sp} {
			flex-direction: row;
		}
		.l-flex__item {
			width: calc(33.3% - 25px);
			@media #{$sp} {
				width: 100%;
				margin-bottom: 30px;
			}
		}
	}
	// 6カラム
	&.-col6 {
		@extend .-col3;
		.l-flex__item {
			width: calc(100%/6 - 25px);
			@media #{$sp} {
				width: calc(100%/3 - 25px);
				margin-bottom: 30px;
			}
		}
	}
}

.l-flex-sb{
	display: flex;
	justify-content: space-between;
	@media #{$pc} {
		&--pc {
			display: flex;
			justify-content: space-between;
		}
	}
	@media #{$sp} {
		&--sp {
			display: flex;
			justify-content: space-between;
		}
	}
}

.l-flex-c{
	display: flex;
	justify-content: center;
	@media #{$pc} {
		&--pc {
			display: flex;
			justify-content: center;
		}
	}
	@media #{$sp} {
		&--sp {
			display: flex;
			justify-content: center;
		}
	}
}
.l-flex-wrap {

}
.l-flex-start{
	display: flex;
	justify-content: flex-start;
}
.l-flex-end{
	display: flex;
	justify-content: flex-end;
}
.l-flex-sb-c{
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.l-flex-sb-end{
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}
.l-flex-c-c{
	display: flex;
	justify-content: center;
	align-items: center;
}
.l-flex-s-c{
	display: flex;
	align-items: center;
}
.l-flex-column-sb{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.flex1 {
	flex: 1;
}


// 2カラム専用 flex
.l-column {
	display: flex;
	justify-content: space-between;
	&__main {
		flex: 1;
		position: relative;
		& > p:first-child {
			margin-top: -0.4em;
		}
	}
	&__main + &__sub {
		padding-left: 40px;
	}
	&__sub + &__main {
		padding-left: 40px;
	}

	@media #{$pc} {
		&.-reverse {
			.l-column__main {
				order: 1;
			}
			.l-column__sub {
				order: 2;
			}
			.l-column__sub + .l-column__main,
			.l-column__main + .l-column__sub {
				padding-left: 0;
				padding-right: 20px;
			}
		}
	}

	@media #{$sp} {
		//dashboard: block;
		flex-flow: column;
		&__main + &__sub {
			padding-left: 0;
		}
		&__sub + &__main {
			padding-left: 0;
		}
		&__main {
			//order: 2;
		}
		&__sub {
			//order: 1;
			//margin: 1em 0;
			text-align: center;
			img {
				max-width: 100%;
			}
		}
	}
}

// 特殊2カラム
/*
	縦方向に積み上げて以下の順番で左右に並べる
	1 | 2
	3 | 2
	左列に1、3、右列に2が大きく並ぶ
 */
.l-column2-dir {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	max-height: 800px;
	&__item {
		&.-item01,
		&.-item02,
		&.-item03 {
		}
		@media #{$pc} {
			&.-item01,
			&.-item03 {
				//width: 70%;
				//max-width: 639px;
				width: calc(100% - 461px - 30px);
			}
			&.-item01 {
				order: 1;
			}
			&.-item02 {
				order: 3;
				width: 461px;
				padding-left: 57px;
			}
			&.-item03 {
				order: 2;
			}
		}
	}
}

// box center
.l-center-ma { margin-right: auto; margin-left: auto; }

// text align
.l-center { text-align: center; }
.l-left { text-align: left; }
.l-right { text-align: right; }
.l-top { vertical-align: top !important;}
.l-middle { vertical-align: middle !important;}
.l-bottom {vertical-align: bottom !important;}



// gird-colum
.l-grid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	grid-auto-rows: 1fr;
	grid-gap: 1em;
	& > * {
		img {
			width: 100%;
		}
	}
	&.-col100 {
		grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
	}
	&.-col200 {
		grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	}
	&.-col300 {
		grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	}
	&.-col400 {
		grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
	}
}

// float
.fl-left {
	float: left;
	margin-right: 20px;
	&--pc {
		@media #{$pc} {
			float: left;
			margin-right: 20px;
		}
		@media #{$sp} {
			display: block;
			float: none;
		}
	}
	&--sp {
		@media #{$pc} {
			display: block;
			float: none;
			margin-right: 0;
		}
		@media #{$sp} {
			float: left;
			margin-right: 20px;
		}
	}
}
.fl-right {
	float: right;
	margin-left: 20px;
	&--pc {
		@media #{$pc} {
			float: right;
			margin-left: 20px;
		}
		@media #{$sp} {
			display: block;
			float: none;
		}
	}
	&--sp {
		@media #{$pc} {
			display: block;
			float: none;
			margin-left: 0;
		}
		@media #{$sp} {
			float: right;
			margin-left: 20px;
		}
	}
}
