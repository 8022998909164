.sec-voice {
	padding-top: 70px;
	padding-bottom: 125px;
	text-align: center;
	.sec-voice__list{
		margin-top: 130px;
		@media #{$sp}{
			padding-left: 15px;
			padding-right: 15px;
		}
		.sec-voice__list-items:not(:first-child) {
			margin-top: 89px;
		}
		.sec-voice__list-items{
			position: relative;
			padding: 81px 50px 31px;
			border: 1px solid #10124d;
			border-radius: 25px;
			@media #{$sp}{
				padding: 81px 30px 31px;
			}
			.sec-voice__sub-title {
				font-size: 3.3rem;
				line-height: 58px;
				line-height: calc(58/33);
				font-family: $aot31;
				color: #10124d;
				@media #{$sp}{
					font-size: 2.2rem;
				}
			}
			.sec-voice__name {
				position: absolute;
				width: 121px;
				height: 121px;
				top: -61px;
				left: 50%;
				transform: translateX(-50%);
			}
			.sec-voice__text {
				margin-top: 20px;
				font-size: 1.8rem;
				line-height: 31px;
				line-height: calc(31/18);
				font-family: $aot101;
				letter-spacing: 0;
				color: #444444;
				@media #{$sp}{
					font-size: 1.4rem;
					text-align: left;
				}
			}
		}
	}

}