//参考のスタイル例
.li-to-crop {
	@include lhCrop(1.9); //line-height: 1.9
}

.lih {
	font-size: 3rem;
	line-height: calc(42/30);
	@include lhCrop(calc(42/30)); //line-height: 1.9
}
.lip {
	font-size: 1.6rem;
	line-height: calc(26/16);
	@include lhCrop(calc(26/16)); //line-height: 1.9
}


