/*
  var : 共通変数
*/
// layout
$main_width:1000px;
$main_width_large:1200px;
$right-gut:25px;
$left-gut:25px;
$right-gut-sp:15px;
$left-gut-sp:15px;


// color
$color_primary : #248dc5;
$color_primary_deep : #045c8c;
$color_primary_light : #E1DECD;
$color_secondary : #B73044;
$color_accent : #10124d;


$color_blue_gray_dark: #37474F;
$color_blue_gray: #78909C;
$color_blue_gray_light : #CFD8DC;
$color_blue_gray_pale : #ECEFF1;

$color_text : #42210b;

$color_error : #f00;

//link
$color_link : $color-primary;
$color_link_hover : $color_link;
$color_link_visited : #01579B;
$color_link_active : $color_secondary;

//gNav
$color_gNav_bg : #fff;
$color_gNav_bg : #fff;
$color_gNav_bg_hover : #fff;
$color_gNav_bg_active : $color_blue_gray_dark;
$color_gNav_bg_current : #fff;
$color_gNav_sp_menu_bg : $color_blue_gray;

//buttons
$color_btn_text : #fff;
$color_btn_bg : $color_primary;
$color_btn_hover_text : #fff;
$color_btn_hover_bg : #fff;
$color_btn_active_text : $color_blue_gray_dark;
$color_btn_active_bg : #fff;
$color_btn_disable_text : #fff;
$color_btn_disable_bg : #fff;


// font　import
@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab');
@import url('https://fonts.googleapis.com/css?family=Arvo');

// font
// $hiragino: Hiragino Sans, 'ヒラギノ角ゴ ProN W3', Hiragino Kaku Gothic ProN, "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
$hiragino: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", Arial, "メイリオ", Meiryo, sans-serif;

$hiragino-msg: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
$hiragino-yugo: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "游ゴシック体", "Yu Gothic", YuGothic, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
$hiragino-sawarabi: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Sawarabi Gothic", "游ゴシック体", "Yu Gothic", YuGothic, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;

$yugo: "Noto Sans","游ゴシック体", "Yu Gothic", YuGothic, Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;

$mincho: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
$helvetica: Helvetica,"ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;

$meiryo: Helvetica Neue, Helvetica, Hiragino Sans, 'ヒラギノ角ゴ ProN W3', Hiragino Kaku Gothic ProN, "メイリオ", Meiryo, sans-seri;

$tuka-gothic:"fot-tsukuardgothic-std", sans-serif;

$meiryo:'メイリオ', Meiryo,'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', sans-serif;


$yugo: "Noto Sans","游ゴシック体", "Yu Gothic", YuGothic, Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
$roboto: 'Roboto', sans-serif;
$roboto-slab: 'Roboto Slab', serif;
$Arvo: 'Arvo', serif;

$agency: agency-fb, sans-serif;
$aot31: a-otf-midashi-mi-ma31-pr6n, serif;
$aot101: a-otf-futo-min-a101-pr6n, serif;
$aotMiddle: a-otf-gothic-bbb-pr6n, sans-serif;
$sakkal: "Sakkal Majalla",serif;
$vendetta:vendetta, serif;
$kozuka-micho:kozuka-mincho-pr6n, serif;
/*
font-style: normal;
font-weight: 700;
*/


/*
Bootstrap4の設定例）
xs (Extra Small): ~575px
sm (Small): 576px~767px
md (Medium): 768px〜991px
lg (Large): 992px~1199px
xl (Extra Large): 1200px〜
*/

$bp_xs:380; // スマホ最小対応サイズ
$bp_sm:576; // 〜未満スマホ） landscape phones
$bp_md:768; // 〜未満スマホ大（横向き)、以上タブレット〜
$bp_lg:992; // 〜PC小
$bp_xl:1280; // PC大〜
$bp_xxxl:1650; // PC大〜
$ttl-ch-w:1150;

$xs-up: "screen and (min-width: #{$bp_xs}px)";
$sm-up: "screen and (min-width: #{($bp_sm)}px)";
$md-up: "screen and (min-width: #{$bp_md}px)";
$lg-up: "screen and (min-width: #{($bp_lg)}px)";
$xl-up: "screen and (min-width: #{($bp_xl)}px)";
$xxxl-up: "screen and (min-width: #{($bp_xxxl)}px)";

$xs-down: "screen and (max-width: #{($bp_xs - 0.02)}px)";
$sm-down: "screen and (max-width: #{($bp_sm - 0.02)}px)";
$md-down: "screen and (max-width: #{$bp_md - 0.02}px)";
$lg-down: "screen and (max-width: #{($bp_lg) - 0.02}px)";
$xl-down: "screen and (max-width: #{($bp_xl) - 0.02}px)";
$xxxl-down: "screen and (max-width: #{($bp_xxxl) - 0.02}px)";
$ttl-ch: "screen and (max-width: #{$ttl-ch-w - 0.02}px)";

$xs-only: "screen and (min-width: #{$bp_xs}px) and (max-width: #{($bp_sm - 0.02)}px)";
$sm-only: "screen and (min-width: #{($bp_sm)}px) and (max-width: #{($bp_md - 0.02)}px)";
$md-only: "screen and (min-width: #{$bp_md}px) and (max-width: #{($bp_lg - 0.02)}px)";
$lg-only: "screen and (min-width: #{($bp_lg)}px) and (max-width: #{($bp_xl - 0.02)}px)";
$xl-only: "screen and (min-width: #{($bp_xl)}px)";

// 省略形
$sm: $xs-down;
$sp: $md-down;
$tablet:$md-only;
$pc: $md-up;
$title-change:$ttl-ch;

// スマホ・タブレット
@media #{$sp} {
}
// タブレット専用
@media #{$tablet} {
}
// PC専用
@media #{$pc} {
}

// スマホ以下
@media #{$sm-down} {
}
// 〜タブレット未満
@media #{$md-down} {
}
// 〜PC小未満
@media #{$lg-down} {
}
// 〜PC大未満
@media #{$xl-down} {
}
@media #{$xxxl-down} {
}

// スマホ以上
@media #{$xs-up} {
}
@media #{$sm-up} {
}
@media #{$md-up} {
}
@media #{$lg-up} {
}
@media #{$xl-up} {
}

@media #{$xs-only} {
}
@media #{$sm-only} {
}
@media #{$md-only} {
}
@media #{$lg-only} {
}
@media #{$xl-only} {
}
