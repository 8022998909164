//line-height
@mixin lhCrop($line-height) {
	&::before {
		content: '';
		display: block;
		height: 0;
		width: 0;
		margin-top: calc((1 - #{$line-height}) * 1em);
	}
	&::after {
		content: '';
		display: block;
		height: 0;
		width: 0;
		margin-bottom: calc((1 - #{$line-height}) * 1em);
	}
}

@mixin opacity($opacity) {
	opacity: $opacity;
	filter: alpha(opacity=($opacity * 100));
}

@mixin clearfix() {
	&:before,
	&:after {
		content: "";
		display: table;
	}
	&:after {
		clear: both;
	}
}

@mixin font($fontsize, $lineheight) {
	$rem: $fontsize / 10;
	$linS: $lineheight / $fontsize;
	font-size: #{$rem}rem;
	line-height: $linS;
}

@mixin sentence() {
	font-family: $meiryo;
	transform: rotate(0.028deg);
	letter-spacing: .02em;
}

/* media quary */
$mediumRange: 767px;
@mixin mq($media) {
	@if $media == sp {
		@media screen and (max-width: #{$mediumRange}) {
			@content;
		}
	}
}

// Font Awesome 5
@mixin fa() {
	font-family: "Font Awesome 5 Free";
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	font-weight: bold;
}
@mixin fab() {
	font-family: "Font Awesome 5 Brands";
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	font-weight: bold;
}


@mixin linkSet($link:false) {
	&:link {
		@if $link {
			color: $link;
		}
		@else {
			color: $color_link;
		}
	}
	&:visited {
		color: $color_link_visited;
	}
	&:hover{
		color: $color_link_hover;
		text-decoration: underline;
	}
	&:active {
		color: $color_link_active;
	}
}

@mixin resetList() {
	position: relative;
	margin-bottom: 0;
	padding-left:0;
	line-height: 0;
	&:before {
		content: none !important;
		transform: scale(1);
	}
}


// ------------------------------
// 文字サイズ・行高・文字間の一括指定
//
// $fontSize: カンプの文字サイズ数値
// $lineHeight: カンプの行高数値
// $letterSpacing: カンプの文字間のトラッキング数値
// $margin: マージンを取るか default: false(取らない)
// ------------------------------

@mixin fontSetting($fontSize: 0, $lineHeight: 0, $letterSpacing: 0, $margin: false) {
	// font-size
	@if $fontSize != 0 {
		font-size: ($fontSize / 10) + rem;
	}
	// line-height
	@if $lineHeight != 0 {
		line-height: $lineHeight / $fontSize;
	}
	@if $lineHeight != 0 and $margin == true {
		// line-heightで生まれる上下の余白を相殺
		$space: -($lineHeight - $fontSize) / 10 / 2;
		@if $space != 0 {
			&:before, &:after {
				content: '';
				display: block;
				width: 0;
				height: .00001px; // Safari対策
				overflow: hidden;
			}
			&:before {
				margin-bottom: $space + rem;
			}
			&:after {
				margin-top: $space + rem;
			}
		}
	}
	// letter-spacing
	@if $letterSpacing != 0 {
		letter-spacing: ($letterSpacing / 1000) + em;
	}
}


