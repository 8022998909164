.accordion-button{
	margin-bottom: 8px;
	cursor: pointer;
	display: inline-block;
	&:hover{
		text-decoration: underline;
	}
	img{
		transform: rotate(90deg);
		transition: 0.4s;
		margin: 0 10px 0 8px;
	}
	&.active{
		img{
			transform: rotate(-90deg);
			transition: 0.4s;
		}
	}
}

.accordion-content{
	display: none;
	//overflow: hidden;
	//height: 0;
	//transition: 0.4s;
	//@include opacity(0);
	&.active{
		//height: auto;
		//transition: 0.4s;
		//@include opacity(1);
	}
}