* {
	box-sizing: border-box;
}

:root {
	font-size: 62.5%;
}

html, body {
	min-height: 100%;
	margin: 0;
	padding: 0;
}
body {
	padding: 0;
	margin: 0;
	font-family: $mincho;
	font-size: 1.6rem;
	color: $color_text;
	letter-spacing: 0.06em;
	line-height:16px;
	line-height:calc(30/16);
}

main{
	display: block;
}

a {
	color: #004AA1;
	text-decoration: none;
	@include linkSet();
	//&:link {
	//	color: $color_link;
	//}
	//&:visited {
	//	color: $color_link_visited;
	//}
	//&:hover{
	//	color: $color_link_hover;
	//	text-decoration: underline;
	//}
	//&:active {
	//	color: $color_link_active;
	//}
}
a:hover {
	text-decoration: none;
}
a:hover, a:focus {
	text-decoration: none;
	color: inherit;
	-webkit-transition: all .5s;
	transition: all .5s;
}

figure {
	margin: 0;
}

img{
	max-width: 100%;
	vertical-align: bottom;
	@include mq(sp) {
		max-width: 100%;
		height: auto;
	}
}
h1,h2,h3,h4,h5 {
	margin: 0;
}

ul, ol {
	margin: 0;
	padding: 0;
}

hr {
	height: 0;
	border: 0;
	border-top: 1px solid #ccc;
	margin-top: 2em;
	margin-bottom: 2em;
}
dl {
	dt{
		margin: 0;
	}
	dd {
		margin: 0;
	}
}
p {
	margin: 0;
}
button {
	border: none;
	padding: 0;
}
body button:focus {
	background:none;
}

.sp-hidden {
	@media #{$sp} {
		display: none;
	}
}
.pc-hidden {
	display: none;
	@media #{$sp} {
		display: block;
	}
}
.hidden {
	display: none;
}

.icon-arrow-right {
	width: 5px;
	height: 8px;
	@media #{$sp} {
		width: 8px;
		height: 13px;
	}
}
//
.sp-hidden {
	@media #{$sp} {
		display: none;
	}
}

.pc-hidden {
	display: none;
	@media #{$sp} {
		display: block;
	}
}

//font-awesome
@import url(//use.fontawesome.com/releases/v5.0.12/css/all.css);

/* iOSでのデフォルトスタイルをリセット */
button {
	border-radius: 0;
}
input[type="submit"],
input[type="reset"],
input[type="button"] {
	border-radius: 0;
	-webkit-box-sizing: content-box;
	-webkit-appearance: button;
	appearance: button;
	border: none;
	box-sizing: border-box;
	cursor: pointer;
	-webkit-appearance: none;
	padding: 10px;
}
input[type="submit"]::-webkit-search-decoration,
input[type="reset"]::-webkit-search-decoration,
input[type="button"]::-webkit-search-decoration {
	display: none;
}
input[type="submit"]:focus,
input[type="reset"]:focus,
input[type="button"]:focus {
	outline-offset: -2px;
}
