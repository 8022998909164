.sec-blog {
	padding-top: 70px;
	padding-bottom: 152px;
	text-align: center;
	background-color: #ecedf3;
	.sec-blog__list {
		width: 940px;
		margin: 93px auto 0;
		display: flex;
		justify-content: space-between;
		@media #{$sp}{
			width: 100%;
			flex-direction: column;
			align-items: center;
		}
		li:not(:first-child){
			@media #{$sp}{
				margin-top: 30px;
			}
		}
		li {
			width: 244px;
			height: auto;
			.sec-blog__list-items {
				display: flex;
				flex-direction: column;
				border: 6px solid #10124d;
				padding-bottom: 34px;
				transition: 1s;
				.sec-blog__list-head {
					order:2;
					display: flex;
					flex-direction: column-reverse;
					.sec-blog__list-title {
						margin-top: 12px;
						font-size: 1.2rem;
						line-height: 21px;
						letter-spacing: 0;
						font-family: $aot31;
						font-weight: 600;
						font-style: normal;
						color: #000;
					}
					.sec-blog__list-time {
						width: 176px;
						margin: 0 auto;
						font-size: 2.8rem;
						line-height: 50px;
						letter-spacing: 0;
						font-family: $aot31;
						font-weight: 600;
						font-style: normal;
						color: #000;
						border-bottom: 1px solid #000;
					}
				}
				.sec-blog__list-image {
					order: 1;
					border-bottom: 6px solid #10124d;
				}
			}
			.sec-blog__list-items:hover {
				background-color: #fff;
			}
		}
	}
}