.sub-visual {
  position: relative;
  content: "";
  top: 0;
  right: 0;
  margin-top: 94px;
  width: 100%;
  height: 424px;
  background-image: url(../../_assets/img/page/sub-visual_01.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  @media #{$sp}{
    height: auto;
    margin-top: 59px;
    padding: 40px 0;
  }
  .sub-visual__contents {
    width: 650px;
    height: 190px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    @media #{$sp}{
      width: 100%;
     margin-left: 15px;
     margin-right: 15px;
    }
    .sub-visual__main {
      width: 336px;
      height: 140px;
      .sub-visual__main__title {
        position: relative;
        width: 320px;
        padding-left: 100px;
        margin: 0 auto;
        color: $color_accent;
        display: flex;
        flex-direction: column;
        @media #{$sp}{
          padding-left: 73px;
        }
        .head {
          font-size: 2.7rem;
          font-family: $aot101;
          font-weight: 400;
          font-style: normal;
          line-height: 1;
          letter-spacing: -1px;
          @media #{$sp}{
            font-size: 2.2rem;
            letter-spacing: 0;
          }
        }
        .bottom {
          margin-top: 8px;
          font-size: 2.1rem;
          font-family: $aot101;
          font-weight: 400;
          font-style: normal;
          line-height: 1;
          letter-spacing: -7px;
          @media #{$sp}{
            font-size: 1.8rem;
            letter-spacing: 0;
          }
        }
      }
      .sub-visual__main__text {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 224px;
        margin: 28px auto 0;
        color: $color_accent;
        @media #{$sp}{
          margin: 20px auto 0;
        }
        .head {
          padding-bottom: 7px;
          font-size: 2.1rem;
          font-family: $aot101;
          font-weight: 400;
          font-style: normal;
          line-height: 1;
          border-bottom: 1px solid $color_accent;
        }
        .bottom {
          margin-top: 8px;
          font-size: 2.3rem;
          font-family: $vendetta;
          font-weight: 300;
          line-height: 1;
          font-style: italic;
        }
      }
      .sub-visual__main__title:before {
        position: absolute;
        content: "";
        top: 50%;
        left: 0;
        width: 84px;
        height: 53px;
        background-image: url(../../_assets/img/page/icon_sub_visual.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        transform: translateY(-50%);
        @media #{$sp}{
          width: 50px;
          height: 33px;
        }
      }
    }
  }
}

.main-visual {
  position: relative;
  content: "";
  top: 0;
  right: 0;
  margin-top: 94px;
  width: 100%;
  height: 648px;
  background-image: url(../../_assets/img/index/main_bg.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @media #{$sp}{
    margin-top: 60px;
  }
  .main-visual__copy-wrap {
    padding-top: 214px;
    text-align: center;
    .main-visual__copy01 {
      font-size: 64px;
      line-height: 1;
      font-family: $agency;
      font-weight: 400;
      font-style: normal;
      color: #fff;
    }
    .main-visual__copy02 {
      margin-top: 10px;
      font-size: 5.4rem;
      font-family: $sakkal;
      line-height: 1;
      font-weight: bold;
      color: #fff;
    }
  }

  .main-visual__text {
    margin-top: 39px;
    color: #fff;
    text-align: center;
    font-family: $aot31;
    font-size: 1.5rem;
    line-height: 18px;
    letter-spacing: 0.1em;
    font-weight: 600;
    font-style: normal;
    @media #{$sp}{
      padding: 0 15px;
    }

  }
  .main-visual__scroll {
    position: absolute;
    width: 43px;
    height: 94px;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    img {
      width: 100%;
      animation-fill-mode:both;
      animation-duration:2s;
      animation-iteration-count: infinite;
      animation-name: move;
      visibility: visible !important;
      @-webkit-keyframes move {
        0% { opacity: 0; -webkit-transform: translateY(-30px); }
        100% { opacity: 1; -webkit-transform: translateY(0); }
      }
      @keyframes move {
        0% { opacity: 0; -webkit-transform: translateY(-30px); -ms-transform: translateY(-30px); transform: translateY(-30px); }
        100% { opacity: 1; -webkit-transform: translateY(0); -ms-transform: translateY(0); transform: translateY(0); }
      }
    }
  }
}


