@charset "utf-8";

.clearfix {
	@include clearfix;
}
.clear { clear: both;}

.m0 {margin: 0!important;}
.p0 {padding: 0!important;}

.pd-none {
	padding: 0 !important;
}



/*  hide anchor */
/*----------------------------------------------------------------------------------------------------------------------------------------*/

.show { display: block;}
.hide { display: none;}

@media #{$sp} {
	.forPC { display: none !important;}
	.forPC2 { display: none !important;}
	.forSP { display: block !important;}
	.forSP2 { display: inline-block !important;}

	.-forPC { display: none !important;}
}
@media #{$pc} {
	.forPC { display: block !important;}
	.forPC2 { display: inline-block !important;}
	.forSP { display: none !important;}
	.forSP2 { display: none !important;}

	.-forSP { display: block !important;}
}

@media #{$sp} {
	.spmb0{
		margin-bottom: 0 !important;
	}
	.spmt0{
		margin-top: 0 !important;
	}
}



/*  box layout */
/*----------------------------------------------------------------------------------------------------------------------------------------*/

.minh-none {  min-height: inherit !important;}
.maxh-none {  max-height: inherit !important;}
.minw-none {  min-width: inherit !important;}
.maxw-none {  max-width: inherit !important;}
