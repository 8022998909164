.table{
	&.-type1 {
		border-bottom: 1px solid $color_secondary;
		th, td{
			border-top: 1px solid $color_secondary;
			border-bottom: none;
			border-right: 0;
			border-left: 0;
			vertical-align: top;
			line-height: 1.88;
			background: transparent;
		}
		th {
			padding-left: 2em;
			text-align: right;
			white-space: nowrap;
		}
		td {
			text-align: left;
			padding-left: 2em;
		}
		@media #{$sp} {
			th,td {
				padding-top: 0.8em;
				padding-bottom: 0.8em;
				line-height: 1.38;
			}
			th{
				padding-left: 0;
				text-align: left;
				font-size: 1.2rem;
			}
			td {
				padding-left: 1em;
				font-size: 1.3rem;
			}
		}
	}

	&.-type2 {
		border-bottom: 1px solid #ccc;
		th, td{
			border-top: 1px solid #ccc;
			border-bottom: none;
			border-right: 0;
			border-left: 0;
			vertical-align: top;
			line-height: 1.88;
			background: transparent;
      //overflow-wrap: normal;
      //word-break: keep-all;
		}
		th {
			white-space: nowrap
		}
		thead {
			th,td {
				border-top: none;
			}
			th {
        width: 80px;
				color: #666;
				&:nth-child(2) {
          width: 45%;
					background-color: #ECEFF1;
				}
				&:nth-child(3) {
          width: 45%;
					background-color: #DBE0E3;
				}
			}
		}
		tbody {
			th {

			}
			div div {
				width: 47%;
        @media #{$sp} {
          width: 100%;
        }
			}
		}
		
		@media #{$sp} {
			tr,th,td {
			}
			th,td {
				padding: 12px 0;
				font-size: 1.3rem;
			}
			td {
				padding-left: 16px;
			}
		}
	}

	&.-type3 {
		font-size: 1.6rem;
		th {
			width: 50%;
			border: none;
			color: #fff;
			text-align: center;
			font-weight: bold;
			&.col1 {
				background-color: #666;
			}
			&.col2 {
				background-color: $color_primary;
			}
		}
		td {
			padding: 1em;
			border: none;
			border-bottom: 1px solid #ccc;
			font-size: 1.6rem;
			&.col1 {
				background-color: #F6F8F9;
			}
			&.col2 {
				font-weight: bold;
				background-color: #fff;
			}
		}
		tr:nth-child(even) {
			td {
				&.col1 {
					background-color: #ECEFF1;
				}
				&.col2 {
					background-color: #D8F4F8;
				}
			}
		}
		@media #{$sp} {
		}
	}


	&.-price {
		@media #{$sp} {
			min-width: 550px;
		}

		thead {
			tr {
				border: none;
			}
			th {
				color: #333;
				font-weight: normal;
				font-size: 2rem;
				//font-family: "Roboto";
				border: none;
				background: transparent;
				border-radius: 0;
				text-align: center;
				vertical-align: top;
			}
		}
		tbody {
			tr {
				border: none;
			}
			th,td {
        width: 33%;
				padding: 1em;
				border: none;
				border-radius: 0;
				font-size: 1.6rem;
				@media #{$sp} {
					padding: 0.8em;
					font-size: 1.4rem;
				}

			}
			th {
				font-weight: normal;
				background-color: #F0F3F5;
				@media #{$sp} {
					width: 120px;
				}
			}
			td {
				font-weight: bold;
				text-align: center;
				vertical-align: middle;
			}

			.col1 {
				background-color: rgba(#FFCA28, 0.3);
			}
			.col2 {
				background-color: #D8F4F8;
			}
			tr:nth-child(even) {
				th {
					background-color: #fff;
				}
				.col1 {
					background-color: #fff;
				}
				.col2 {
					background-color: #F7FDFE;
				}
			}

			.fa-circle {
				font-size:26px;
				//font-weight: bold;
				color: $color_primary;
			}
		}

		// Free列だけボーダーを囲む
		thead .col1 {
			border: 3px solid $color_accent;
			border-bottom: none;
		}
		tbody {
			.col1 {
				border: 3px solid $color_accent;
				border-width: 0 3px;
			}
			tr:last-child {
				.col1 {
					border-bottom-width: 3px;
				}
			}
		}

		// 料金見出し
		.plan {
			padding: 15px 15px 20px;
			vertical-align: top;
			@media #{$sp} {
				padding: 10px;
			}
			&__title {
				margin: 0;
				font-size: 2rem;
				font-weight: normal;
				@media #{$sp} {
					font-size: 1.6rem;
				}
			}
			&__price {
				margin-bottom: 2px;
				font-size: 2rem;
				font-weight: bold;
				color: $color_primary;
				@media #{$sp} {
					font-size: 1.6rem;
				}
				strong {
					font-size: 1.5em;
				}
				.small	{
					font-size: 0.75em;
				}
			}
		}

		// 注意文
		.notice {
			display: block;
			padding-top: 0.2em;
			text-align: center;
			font-size: 1.3rem;
			font-weight: normal;
		}
	}
}


.tableWrapScroll {
	@media #{$sp} {
		width: 100%;
		overflow-x: auto;
	}
}