.sec-news {
	padding-top: 43px;
	padding-bottom: 64px;
	text-align: center;
	color: #10124d;
	font-size: 1.4rem;
	letter-spacing: 0.4em;
	.sec-news__list {
		margin: 40px auto 0;
		width: 320px;
		.sec-news__list-items {
			margin-top: 11px;
			padding-bottom: 10px;
			border-bottom: 1px solid #000;
			.list-items__link {
				display: flex;
				color: #000;
				.list-items__time {
					margin-right: 20px;
					font-size: 1.4rem;
					font-family: $aot101;
					font-weight: 400;
					font-style: normal;
					letter-spacing: 0;
				}
				.list-items__title {
					font-size: 1.4rem;
					font-family: $aot101;
					font-weight: 400;
					font-style: normal;
					letter-spacing: 0;
				}
			}
		}
		.sec-news__list-items:first-child {
			margin-top: 0;
		}
	}
	.sec-news__list-btn-wrap {
		margin-top: 30px;
		.sec-news__list-btn {
			padding: 5px 64px;
			border: 1px solid #10124d;
			font-size: 1.5rem;
			line-height: 1;
			letter-spacing: 0.4em;
			font-family: $aotMiddle;
			color: #10124d;
			transition: 1s;
		}
		.sec-news__list-btn:hover {
			background-color: #10124d;
			color: #fff;
		}
	}
}